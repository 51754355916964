function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

window.addEventListener('heyflow-screen-view', (event) => {
    
  /*  const inputBlocks = Array.from(document.querySelectorAll(".block-content.elements-container .block"))
    
    console.log({inputBlocks})
    const numberBlock = inputBlocks.find(block => block.querySelector("#input-55f75925-container"))
    const streetAddressBlock = inputBlocks.find(block => block.querySelector("#input-93ecbd90-container"))
   
   streetAddressBlock.style.width ="75%";
   numberBlock.style.width ="20%";
   ([streetAddressBlock, numberBlock]).forEach(element=>{
        element.style.flexGrow = '0';         // Default for flex-grow
        element.style.flexShrink = '1';       // Default for flex-shrink
        element.style.flexBasis = 'auto';     // Default for flex-basis
        element.style.maxWidth = 'none';      // Default for max-width

   })
   */
    
    /** OFFERS CODE **/
    const offersTextElement = document.querySelector('#text-block-rich-text-66bac272 p')
    const randomNumber = getRandomNumber(50,150)
    if(offersTextElement){
        offersTextElement.innerHTML= `I ditt område har <strong>${randomNumber}</strong> kunder fått <strong>kostnadsfria</strong> erbjudanden under de senaste 7 dagarna.`



    }
    // console.log('heyflow screen view:', event.detail);
    /** OFFERS CODE END**/
    /** Autocomplete code **/
    const inputAddressElement = document.querySelector('[data-variable="input-street1"]');
    const inputStreet = document.querySelector('[data-variable="street1"]')
    const inputStreetNumber = document.querySelector('[data-variable="street2"]')
    const inputPostalCode = document.querySelector('[data-variable="postcode"]')
    const inputCity = document.querySelector('[data-variable="towncity"]')
    
    console.log("Input address element", inputAddressElement)
    if (inputStreet) {
        const autocomplete = new google.maps.places.Autocomplete(inputStreet, {
            types: ['address'],
            componentRestrictions: { country: 'se' }
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.error("No details available for input: '" + place.name + "'");
                return;
            }
            //console.log('Selected place:', place.formatted_address);
            //console.log(place)
            
                      
              const addressComponents = {};
            
              place.address_components.forEach(component => {
                component.types.forEach(type => {
                  addressComponents[type] = component.long_name;
                });
              });
               console.log(addressComponents) 
              const deconstructed = {
                streetName: addressComponents.route || '',
                streetNumber: addressComponents.street_number || '',
                postcode: addressComponents.postal_code || '',
                city: addressComponents.postal_town || addressComponents.locality || addressComponents.administrative_area_level_1 || '',
            };
            inputStreetNumber.value=deconstructed.streetNumber
            inputStreet.value=deconstructed.streetName
            inputPostalCode.value=deconstructed.postcode
            inputCity.value=deconstructed.city
            
           // inputAddressElement.value=place.formatted_address
            
        });
    } else {
        console.error('Input element not found');
    }
    /** Auto complete code end **/

});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});
